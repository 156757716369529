/* eslint-disable react/no-danger -- when updating this component, consider replacing dangerous jsx properties */
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import React, { useEffect, memo } from 'react';

import { token } from '@atlaskit/tokens';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { Y500 } from '@atlaskit/theme/colors';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { requireSuperBatch, loadResources } from '@confluence/wrm';
import { evalLegacyConnectInlineScripts } from '@confluence/connect-utils';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

import type { CustomHeaderAndFooterQuery_space_settings_customHeaderAndFooter_footer_spaUnfriendlyMacros as SpaUnfriendlyMacroType } from './__types__/CustomHeaderAndFooterQuery';
import { CUSTOM_HEADER_FOOTER_TYPES } from './constants';
import {
	StyledRoot,
	StyledUnsupportedMacro,
	StyledHeader,
	StyledFooter,
} from './PresentationalComponents';

type CustomHeaderFooterComponentProps = {
	type: 'header' | 'footer';
	spaUnfriendlyMacros: SpaUnfriendlyMacroType[];
	js: string[];
	css: string;
	html: string;
};

const i18n = defineMessages({
	unsupportedMacrosTitle: {
		id: 'custom-header-footer.unsupported.macros.title',
		defaultMessage: 'Unsupported macros',
		description: 'Space cutomized header and footer is imcompitable with unsupported macros',
	},
});

export const CustomHeaderFooterComponent = memo(
	({ spaUnfriendlyMacros = [], type, js, css, html }: CustomHeaderFooterComponentProps) => {
		const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
		const intl = useIntl();
		const HeaderOrFooterComponent =
			type === CUSTOM_HEADER_FOOTER_TYPES.HEADER ? StyledHeader : StyledFooter;
		const bodyRef = React.createRef<HTMLDivElement>();
		const scriptRef = React.createRef<HTMLDivElement>();
		// remove duplicates
		spaUnfriendlyMacros = Array.from(new Set(spaUnfriendlyMacros));

		const { createAnalyticsEvent } = useAnalyticsEvents();

		useEffect(() => {
			createAnalyticsEvent({
				type: 'sendScreenEvent',
				data: {
					name: 'customHeaderAndFooter',
					attributes: {
						type: type === CUSTOM_HEADER_FOOTER_TYPES.HEADER ? 'header' : 'footer',
					},
				},
			}).fire();
		}, [createAnalyticsEvent, type]);

		useEffect(() => {
			if (!scriptRef.current || !bodyRef.current) {
				return;
			}
			void requireSuperBatch().then(() => {
				if (scriptRef.current) {
					void loadResources(scriptRef.current, js).then(() => {
						if (bodyRef.current) {
							void evalLegacyConnectInlineScripts(bodyRef.current);
						}
					});
				}
			});
		});

		return (
			<StyledRoot data-vc="custom-header-footer" {...ssrPlaceholderIdProp}>
				{spaUnfriendlyMacros.length ? (
					<StyledUnsupportedMacro>
						<WarningIcon
							primaryColor={token('color.icon.warning', Y500)}
							label={intl.formatMessage(i18n.unsupportedMacrosTitle)}
						/>
						<FormattedMessage
							id="custom-header-footer.unsupported.macros.list"
							defaultMessage="The following macros are not currently supported in the {type}:"
							description="The warning message when a custom space header or footer contain incompatible macros. type variable equals to 'header' or 'footer'"
							values={{ type }}
						/>
						<ul>
							{spaUnfriendlyMacros.map(
								(macro) => macro && macro.name && <li key={macro.name}>{macro.name}</li>,
							)}
						</ul>
					</StyledUnsupportedMacro>
				) : null}
				<div dangerouslySetInnerHTML={{ __html: css }} />
				<HeaderOrFooterComponent
					data-test-id={`custom-settings-${type}`}
					dangerouslySetInnerHTML={{ __html: html }}
					ref={bodyRef}
				/>
				<div ref={scriptRef} />
			</StyledRoot>
		);
	},
);
