import gql from 'graphql-tag';

export const CustomHeaderAndFooterQuery = gql`
	query CustomHeaderAndFooterQuery($spaceKey: String) {
		space(key: $spaceKey) {
			id
			settings {
				customHeaderAndFooter {
					header {
						html
						js
						css
						spaUnfriendlyMacros {
							name
						}
					}
					footer {
						html
						js
						css
						spaUnfriendlyMacros {
							name
						}
					}
				}
			}
		}
	}
`;
