import React from 'react';
import { Query } from 'react-apollo';

import { PageSubscriber } from '@confluence/page-context';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';

import { CustomHeaderFooterComponent } from './CustomHeaderFooterComponent';
import { CustomHeaderAndFooterQuery } from './CustomHeaderAndFooterQuery.graphql';
import type {
	CustomHeaderAndFooterQuery as QueryType,
	CustomHeaderAndFooterQueryVariables as QueryVariablesType,
} from './__types__/CustomHeaderAndFooterQuery';

type CustomHeaderFooterProps = {
	type: 'header' | 'footer';
};

export function CustomHeaderFooter({ type }: CustomHeaderFooterProps) {
	return (
		<ErrorBoundary attribution={Attribution.BACKBONE}>
			<PageSubscriber>
				{({ spaceKey, contentId }) => (
					<Query<QueryType, QueryVariablesType>
						query={CustomHeaderAndFooterQuery}
						variables={{ spaceKey }}
					>
						{({ loading, error, data }) => {
							const settings: any = data?.space?.settings?.customHeaderAndFooter?.[type];

							if (loading || error || !settings?.html) {
								return null;
							}

							return (
								<CustomHeaderFooterComponent
									spaUnfriendlyMacros={settings.spaUnfriendlyMacros}
									type={type}
									css={settings.css}
									js={settings.js}
									html={settings.html}
									key={contentId}
								/>
							);
						}}
					</Query>
				)}
			</PageSubscriber>
		</ErrorBoundary>
	);
}
